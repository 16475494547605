import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import "swiper/css";
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
const AddBootstrap = lazy(()=> import ('./libs/AddBootstrap'))


const client= new ApolloClient({
  uri:" https://shivanshartacademy.in/arts/graphql",
  cache: new InMemoryCache(),
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <ApolloProvider client={client}>
  <AddBootstrap/>
    <App />
       <AddBootstrap/>
    </ApolloProvider>
  </BrowserRouter>
);

reportWebVitals(); 
