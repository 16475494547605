import './App.scss';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { lazy, Suspense, useLayoutEffect, useEffect } from 'react';

const Results = lazy(() => import('./layout/Result Page/Results'));
const NotFound = lazy(() => import('./components/NotFound'));
const Navbars = lazy(() => import('./components/Navbar'));
const Page = lazy(() => import('./Pages/Page'));
const SlugGuard = lazy(() => import('./libs/AvoidSlug'));

function App() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const criticalCss = document.createElement('link');
    criticalCss.rel = 'preload';
    criticalCss.as = 'style';
    criticalCss.href = 'critical.css';
    document.head.appendChild(criticalCss);
  }, []);
 

  return (
    <Suspense fallback={<></>}>
      <Navbars/>
      <Routes>
        {/* <Route path='/arts' element={<Navigate to="/arts/wp-admin" replace />} /> */}
        <Route path="/" element={<Page />} />
        <Route path="/home" element={<Navigate to="/" replace />} />
        <Route path='/:uri' element={<SlugGuard />} />
        <Route path='/results/:uri' element={<Results />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
